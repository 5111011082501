<template>
  <div class="topbar-item">
    <div
        id="kt_quick_user_toggle"
        class="btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto"
    >
      <div class="d-flex flex-column text-right pr-3">
        <span
            class="text-white opacity-50 font-weight-bold font-size-sm d-none d-md-inline"
        >{{ form.name }}</span
        >
        <span
            class="text-white font-weight-bolder font-size-sm d-none d-md-inline"
        >{{ form.department }} {{ form.major }}</span
        >
      </div>
      <span class="symbol symbol-35">
        <span
            class="symbol-label font-size-h5 font-weight-bold text-white bg-white-o-30"
        >{{ nameS }}</span
        >
      </span>
    </div>

    <div
        id="kt_quick_user"
        ref="kt_quick_user"
        class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Header-->
      <div
          class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0">
          个人信息
        </h3>
        <a
            href="#"
            class="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
          class="offcanvas-content pr-5 mr-n5 scroll"
          style="max-height: 90vh; position: relative"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <img class="symbol-label" :src="avatarPath" alt=""/>
            <i class="symbol-badge bg-success"></i>
          </div>
          <div class="d-flex flex-column">
            <a
                href="#"
                class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {{ form.name }}
            </a>
            <div class="text-muted mt-1">{{ form.department }} {{ form.major }}</div>
            <div class="navi mt-2">
              <a href="#" class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <span class="navi-icon mr-1">
                    <span class="svg-icon svg-icon-lg svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                          src="media/svg/icons/Communication/Thumbtack.svg"
                      />
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                  <span class="navi-text text-muted text-hover-primary">
                    {{ form.grade }}级
                  </span>
                </span>
              </a>
            </div>
            <button class="btn btn-light-primary btn-bold" @click="onLogout">
              退出登录
            </button>
          </div>
        </div>
        <!--end::Header-->

        <div class="separator separator-dashed my-7"></div>
        <!--begin::Notifications-->
        <div>
          <!--begin:Heading-->
          <h5 class="mb-5">最近通知</h5>
          <!--end:Heading-->
          <template v-for="(item, i) in list">
            <!--begin::Item -->
            <div
                class="d-flex align-items-center rounded p-5 gutter-b"
                v-bind:class="`bg-light-${item.type}`"
                v-bind:key="i"
            >
              <span
                  class="svg-icon mr-5"
                  v-bind:class="`svg-icon-${item.type}`"
              >
                <span class="svg-icon svg-icon-lg">
                  <!--begin::Svg Icon-->
                  <inline-svg :src="item.svg"/>
                  <!--end::Svg Icon-->
                </span>
              </span>
              <div class="d-flex flex-column flex-grow-1 mr-2">
                <a
                    href="#"
                    class="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1"
                >
                  {{ item.title }}
                </a>
                <span class="text-muted font-size-sm">
                  {{ item.desc }}
                </span>
              </div>
              <span
                  class="font-weight-bolder py-1 font-size-lg"
                  v-bind:class="`text-${item.type}`"
              >
                {{ item.alt }}
              </span>
            </div>
            <!--end::Item -->
          </template>
        </div>
        <!--end::Notifications-->
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>
<style>
.symbol.symbol-100 .symbol-label {
  width: 100px;
  height: 150px;
}
</style>
<script>
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";

export default {
  name: "KTQuickUser",
  data() {
    return {
      list: [
        {
          title: "预交住宿费可网上预选宿舍，收到通知书后三个工作日内缴纳学费",
          desc: "近期",
          alt: "",
          svg: "media/svg/icons/Home/Library.svg",
          type: "warning",
        },
        {
          title: "报到时请带好录取通知书和相关证件",
          desc: "温馨提醒",
          alt: "",
          svg: "media/svg/icons/Communication/Write.svg",
          type: "success",
        },
        {
          title: "同学，请注意报到日期为8月17日",
          desc: "重要",
          alt: "",
          svg: "media/svg/icons/Communication/Group-chat.svg",
          type: "danger",
        },
        {
          title: "发生任何问题请第一时间联系老师",
          desc: "温馨提醒",
          alt: "",
          svg: "media/svg/icons/General/Attachment2.svg",
          type: "info",
        },
      ],
      form: "",
      nameS: ""
    };
  },

  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
    this.initUserDetails()
  },
  methods: {
    onLogout() {
      location.href = "/login";
      sessionStorage.setItem("systemToken", "");
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
    initUserDetails() {
      this.$axios({
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: sessionStorage.getItem("systemToken"),
        },
        url: "/api/userDetails/getUserDetailsByUserId", //后端请求的路径
        method: "post",
      }).then((response) => {
        let UserDetails = response.userDetails;
        this.avatarPath = UserDetails.avatarPath;
        this.nameS = UserDetails.name.charAt(0)
        this.form = UserDetails;
      });
    },
  },
  computed: {
    picture() {
      return process.env.BASE_URL + "media/users/300_21.jpg";
    },
    avatarPath: {
      get() {
        return this.$store.getters.getAvatarPath
      }, set(avatarPath) {
        this.$store.commit("changeAvatarPath", avatarPath);
      }

    },
  },
};
</script>
